.card__soon {
	.card__header {
		margin-top: 7px;
	}
	.card__header-top {
		justify-content: center;
	}
	.card__header-text-img {
		margin-top: 10px;
		text-align: center;
		font-family: 'Michroma', sans-serif;
		font-size: 16px;
		letter-spacing: 3.981px;
		font-weight: 400;
		line-height: 90%;
	}
	.card__center_img {
		left: 0;
		transform: translate(0);
		top: 96px;
	}
	.card__title {
		font-family: 'Michroma', sans-serif;
		line-height: 139%;
		letter-spacing: 4px;
	}
	.card__container--type1 .card__logo-text {
		width: 233px !important;
		max-width: 233px;
	}
	.card__title--medium {
		font-size: 24px;
	}
	.card__title--small {
		font-size: 21px;
	}
}
