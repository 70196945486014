.earning-section--dividends {
	.loader-container {
		display: flex;
		justify-content: center;
		padding: 10% 0px;
	}
}
.date-filters {
	position: relative;
	.calendar-container {
		position: absolute;
		width: 345px;
		top: 45px;
	}
	.input-item,
	.input-icon--calendar {
		cursor: pointer;
	}
}

.block-info__token-icon img,
.token-box__icon-1 {
	object-fit: cover;
	height: 100%;
}

.token-block--biger .token-block__icon {
	height: 32px;
	border-radius: 50%;
	overflow: hidden;
}

.table--my-token-table {
	.token-line {
		justify-content: left;
	}
}

.hint-block:hover .tooltip {
	font-weight: 300;
	display: block;
}

.walletconnect-modal__header {
	img {
		width: 50%;
	}
	p {
		width: 50%;
	}
}

.block-content {
	.token-box-wrapper {
		justify-content: end;
	}
}
.dividents-btns .button--width {
	min-width: 148px;
}
// .earning-section--dividents .switch-box {
// 	justify-content: flex-end;
// }

@media screen and (max-width: 1100px) {
	.earning-block-wrapper {
		gap: 0 16px;
	}
}

@media screen and (max-width: 480px) {
	.token-box__name {
		font-size: 14px;
	}
	.earning-stats__item {
		width: 100%;
	}
}
@media screen and (max-width: 835px) {
	.switch-btns {
		justify-content: end;
	}
	.switch-btns--staking .switch-btns__item {
		padding: 0 15px;
	}
}

.sandwich {
	// display: none;
	// @media screen and (max-width: 1379px) {
	// 	display: block;
	// }
	.main__text {
		width: 400px;
		top: 50%;
		transform: translateY(-50%);
	}
	&__line {
		display: block;
		width: 18px;
		height: 1px;
		border-radius: 4px;
		background-color: var(--text-grey);
		&:not(:last-child) {
			margin-bottom: 4px;
		}
	}
	&__main {
		visibility: hidden;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 8;
		&--active {
			visibility: visible;
		}
	}
	&__content {
		position: fixed;
		transition: all 0.3s linear;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0 !important;
		transform: translateY(-100%);
		z-index: 9;
		background-color: rgba(0, 0, 0);
		padding: 34px 0px;
		padding-top: 72px;
		overflow: auto;
		&--active {
			top: 0 !important;
			transform: translateY(0);
		}
	}
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 28px;
	}
	&__close {
		&-btn {
			display: flex;
			align-items: center;
			margin-left: auto;
		}
		&-icon {
			&::before {
				color: var(--text-main);
				font-size: 24px;
			}
		}
	}
	&__lang {
		margin-bottom: 28px;
		.select__drop-link {
			& + .select__drop-link {
				margin-top: 16px;
			}
		}
	}
	&__nav {
		// display: none;
		background: rgb(0, 0, 0);
		background: linear-gradient(
			90deg,
			rgba(0, 0, 0, 0.20772058823529416) 0%,
			rgba(91, 24, 234, 0.07326680672268904) 32%,
			rgba(0, 0, 0, 1) 100%
		);
		padding-top: 100px;
		height: 100%;
		position: relative;
		padding-bottom: 40px;
		// @media screen and (max-width: 1280px) {
		// 	display: block;
		// }
		max-width: 400px;
		.main-nav {
			&__list {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 6px;
				z-index: 1;
			}
			&__link {
				position: relative;
				font-size: 30px;
				color: white;
				font-weight: 600;
			}
			&__icon::before {
				color: var(--text-main);
			}
			&__item {
				position: relative;
				cursor: pointer;
			}
			&__item::before {
				content: '';
				height: 100%;
				width: calc(100% + 30px);
				border-radius: 50px;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 0;
			}
			&__item.small-margin {
				padding-left: 70px;
			}
			&__item.middle-margin {
				margin: 0 auto;
			}
			&__item.ten-margin {
				padding-left: 90px;
			}
			&__item.forty-margin {
				padding-left: 40px;
			}
			&__item.padding-left {
				padding-left: 160px;
			}
		}
	}

	&__select,
	.select {
		flex-wrap: wrap;
		margin-bottom: 28px;
		background: none;
		&--mobile-nav {
			display: none;
			@media screen and (max-width: 940px) {
				display: block;
			}
		}
		.select {
			margin-bottom: 0;
		}
		.select__current {
			justify-content: flex-start;
			// font-weight: 500;
		}
		.header__select .select__current {
			color: var(--text-main);
		}
		.select__arrow {
			margin-left: auto;
		}
		.select__drop {
			position: static;
			box-shadow: none;
			width: 100%;
			margin-top: 28px;
			padding: 0 32px;
			border: none;
		}
		.select__drop-item {
			border-bottom: 0;
			&:not(:last-child) {
				margin-bottom: 28px;
			}
		}
		.select__drop-link {
			padding: 0;
			color: var(--text-grey);
			font-weight: normal;
		}
		.select__drop-icon {
			&:before {
				color: var(--text-grey);
			}
		}
	}
}
.sandwich__nav .main-nav__item:hover {
	background: #ddee4e;
}
.sandwich__nav .main-nav__item:hover::before {
	background: #ddee4e;
}
.main-nav__item:hover .main-nav__link {
	color: black;
}
@media screen and (max-width: 480px) {
	.sandwich__nav .main-nav__link {
		font-size: 24px;
	}
	.sandwich__content {
		padding-top: 36px;
	}
	.sandwich__nav {
		padding-top: 62px;
	}
}

.menu-img {
	position: absolute;
}
.menu-puzzle1 {
	top: 0;
	left: 0;
	width: 90px;
}
.menu-puzzle2 {
	top: 8%;
	left: 31%;
	width: 150px;
}
.menu-puzzle3 {
	top: 40%;
	left: 35%;
	width: 190px;
}
.menu-puzzle4 {
	top: 2%;
	left: 82%;
	width: 120px;
}
.menu-puzzle5 {
	bottom: 0;
	right: 0;
	width: 90px;
}
.menu-puzzle6 {
	bottom: 5%;
	left: 0;
	width: 90px;
}
.menu-puzzle7 {
	left: 64%;
	top: 29%;
	width: 150px;
}

@media screen and (max-width: 1199px) {
	.menu-puzzle2 {
		left: 45%;
	}
	.menu-puzzle3 {
		left: 55%;
	}
}
@media screen and (max-width: 870px) {
	.menu-puzzle2 {
		left: 65%;
	}
}
.mobile-puzzle1,
.mobile-puzzle2,
.mobile-puzzle3,
.mobile-puzzle4,
.mobile-puzzle5,
.mobile-puzzle6,
.mobile-puzzle7 {
	display: none;
}
@media screen and (max-width: 678px) {
	.menu-puzzle1,
	.menu-puzzle2,
	.menu-puzzle3,
	.menu-puzzle4,
	.menu-puzzle5,
	.menu-puzzle6,
	.menu-puzzle7 {
		display: none;
	}
	.mobile-puzzle1,
	.mobile-puzzle2,
	.mobile-puzzle3,
	.mobile-puzzle4 {
		display: block;
	}
	.sandwich__nav {
		background: transparent;
	}
	.mobile-puzzle1 {
		width: 70px;
		bottom: 0;
		left: 0;
	}
	.mobile-puzzle2 {
		width: 40px;
		left: 0;
		top: 30px;
	}
	.mobile-puzzle3 {
		width: 62px;
		right: 0;
		bottom: 30px;
	}
	.mobile-puzzle4 {
		width: 108px;
		right: 0;
		top: 61px;
	}
}
@media screen and (max-width: 500px) {
	.sandwich__nav .main__text {
		display: block;
		width: 330px;
		top: 53%;
		left: 22px;
		transform: translateY(-50%);
	}
}
// /* Дефолтний стан */
// .menu-btn .circle {
// 	fill: #d9f343;
// 	transition: fill 0.3s ease;
// }

// .menu-btn .stoke {
// 	transition: fill 0.3s ease, transform 0.3s ease;
// }

// /* Стан при доданому класі .active */
// .menu-btn.active .circle {
// 	fill: #6d5eea; /* Змінюємо колір, наприклад, на томатний */
// }

// .menu-btn.active .stoke:nth-child(2) {
// 	transform: rotate(45deg); /* Поворот на 45 градусів за годинниковою стрілкою */
// }

// .menu-btn.active .stoke:nth-child(3) {
// 	transform: rotate(-45deg); /* Поворот на 45 градусів проти годинникової стрілки */
// }
/* Дефолтний стан */
.menu-btn {
	width: 42px;
	height: 19px;
	transition: all 0.5s ease;
}

.menu-btn .circle {
	fill: #d9f343;
	transition: fill 0.5s ease;
}

.menu-btn .stoke {
	fill: white;
	transition: transform 0.5s ease, width 0.5s ease, height 0.5s ease;
}

/* Стан при доданому класі .active */
.menu-btn.active {
	width: 33px;
	height: 33px;
}

.menu-btn.active .circle {
	fill: #6d5eea;
	cx: 26;
	cy: 2;
	r: 7;
}

.menu-btn.active .stoke:nth-child(2) {
	width: 35px;
	height: 2.5px;
	transform: rotate(45deg) translate(8px, -14px);
}

.menu-btn.active .stoke:nth-child(3) {
	width: 35px;
	height: 2.5px;
	transform: rotate(-45deg) translate(-14px, 8px);
}
.card__eco-text {
	max-width: 222px;
}
.card__eco-share {
	width: 25px;
}
.card__eco-heart {
	width: 25px;
}
.card__footer-eco {
	backdrop-filter: blur(10px);
	border-radius: 20px;
	margin-left: -12px;
	margin-right: -12px;
	margin-bottom: -12px;
	padding-top: 5px;
	padding-left: 8px;
	padding-right: 8px;
}
.card__container--eco {
	.card__title {
		font-size: 36px;
		margin-bottom: 12px;
	}
	.card__footer--description {
		font-size: 24px;
		font-weight: 300;
		color: #fff;
		line-height: 27px;
		letter-spacing: 2px;
		margin-left: 10px;
		max-width: 220px;
		margin-bottom: 10px;
	}
	.card__block {
		border-radius: 12px;
		margin-bottom: 12px;
		height: 62px;
	}
	.card__block-text {
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 500;
		font-family: 'Monument Sans';
	}
	.card__block--bottom {
		text-transform: uppercase;
		background: rgb(104, 76, 61);
		background: linear-gradient(
			90deg,
			rgba(104, 76, 61, 1) 1%,
			rgba(174, 151, 131, 1) 24%,
			rgba(102, 62, 44, 1) 46%,
			rgba(127, 83, 58, 1) 61%,
			rgba(177, 138, 111, 1) 85%,
			rgba(223, 202, 182, 1) 100%,
			rgba(78, 51, 40, 1) 100%
		);
		color: white;
		text-align: center;
		margin-left: -8px;
		margin-right: -8px;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		padding-top: 8px;
		font-family: 'Monument Sans';
	}
	.card__block--bottom-text {
		display: block;
		font-size: 13px;
		line-height: 10px;
	}
	.card__block--sub-bottom-text {
		font-size: 15px;
	}
	.card__footer-img {
		width: 30px;
	}
	.card__center_img {
		top: 68px;
		width: 170px;
	}
}

.eco-section {
	.container {
		display: flex;
		justify-content: center;
		height: 520px;
		margin-bottom: 60px;
	}

	.card__logo-text {
		font-size: 25px;
		color: white;
		margin-top: -5px;
		font-family: 'Monument Sans';
		max-width: 210px;
	}
	.card__header {
		background-color: rgb(255, 255, 255, 0.2);
		backdrop-filter: blur(15px);
		height: 69px;
		border-radius: 18px;
		padding: 5px;
		padding-top: 9px;
	}
	.card__share {
		width: 32px;
	}
	.card__center_info {
		background-color: rgb(255, 255, 255, 0.2);
		backdrop-filter: blur(15px);
		color: #fff;
		font-size: 18px;
		line-height: 20px;
		padding: 6px 13px;
		border-radius: 18px;
		padding-bottom: 45px;
		margin-top: -10px;
	}
	.container {
		&:nth-child(2) {
			.card__center_info {
				padding-bottom: 5px;
			}
		}
	}
}
.card__ecosystem--contacts {
	.card__block--bottom {
		background: #f0f359;
		color: black;
	}
}
.eco-section--contacts {
	.card__container {
		justify-content: flex-end;
	}
	.card__footer {
		background: rgb(0, 0, 0, 0.4);
		border-radius: 18px;
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	.card__center_info {
		background: rgb(0, 0, 0, 0.4);
		padding-bottom: 10px;
		padding-top: 10px;
		margin-bottom: 15px;
	}
	.card__block {
		background: transparent;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		height: 100%;
		margin-bottom: 0;
		color: white;
		font-weight: 300;

		span {
			display: block;
			font-size: 14px;
		}
	}
	.card__block_img-description--blue {
		color: #0066ff;
	}
	.card__block-img {
		width: 83px;
		height: 83px;
		overflow: hidden;
		img {
			width: 163%;
			margin-left: -18px;
			margin-top: -14px;
		}
		margin-bottom: 5px;
	}
}
.card__ecosystem--mine {
	.card__eco-text {
		max-width: 202px;
	}
	.card__block {
		box-shadow: 0px 0px 10px white;
	}
	.card__footer-eco {
		background: rgb(255, 255, 255, 0.1);
		backdrop-filter: blur(10px);
	}
	.card__block--bottom {
		background: #f0f359;
		color: black;
	}
	.card__top-wrapper {
		background: rgb(0, 0, 0, 0.4);
		border-radius: 18px;
		padding: 10px;
		.card__center_img {
			padding: 26px 0;
			padding-top: 22px;
			position: relative;
			top: 0;
		}
	}
	.card__title {
		margin-bottom: 5px;
	}
	.card__container--eco .card__footer--description {
		max-width: 235px;
		font-size: 20px;
	}
	.card__center_img {
		width: 160px;
	}
}
.eco-section--mine {
	.card__header {
		margin-top: 5px;
		background: rgb(0, 0, 0, 0.4);
	}
	.card__eco-heart {
		width: 30px;
	}
	.card__share {
		width: 34px;
	}
	.card__logo-text {
		margin-top: 3px;
	}
	.card__center_info {
		margin-top: 30px;
		background: rgb(0, 0, 0, 0.4);
		position: relative;
	}
	.container:nth-child(2) .card__center_info {
		padding-bottom: 45px;
	}
	.card__block {
		box-shadow: 0px 0px 10px white;
	}
	.card__coin_back {
		position: absolute;
		top: 120px;
		left: 0;
		width: 48px;
	}
	.card__center_coin {
		position: absolute;
	}
	.center_info_coin-0 {
		width: 100px;
		bottom: -94px;
		right: -12px;
	}
	.center_info_coin-1 {
		width: 100px;
		bottom: -60px;
		right: -12px;
	}
}
.card__ecosystem--exchange {
	.card__footer-eco {
		background: rgba(255, 255, 255, 0.1);
	}
	.card__container--eco .card__footer--description {
		max-width: 100%;
	}
}
.eco-section--exchange {
	.card__center_info {
		margin-top: 30px;
		background: rgb(0, 0, 0, 0.4);
	}
	.card__header {
		margin-top: 5px;
		background: rgb(0, 0, 0, 0.4);
	}
	.card__block {
		box-shadow: 0px 0px 10px white;
	}
	.card__logo-text {
		margin-top: 0px;
	}
}
.card__ecosystem--soon {
	.card__block-text {
		max-width: 180px;
		line-height: 18px;
	}
	.card__top-wrapper {
		background: rgb(0, 0, 0, 0.4);
		border-radius: 18px;
		padding: 10px;
	}
	.card__center_img {
		position: relative;
		top: 0;
		padding: 26px 0;
		padding-top: 22px;
	}
	.card__block--bottom {
		background: rgb(88, 210, 255);
		background: linear-gradient(
			90deg,
			rgba(88, 210, 255, 1) 1%,
			rgba(31, 112, 220, 1) 36%,
			rgba(48, 205, 255, 1) 66%,
			rgba(0, 114, 209, 1) 93%
		);
	}
}

.eco-section--soon {
	.card__container--eco {
		overflow: hidden;
	}
	.card__header {
		margin-top: 5px;
		background: rgb(3, 40, 31, 92%);
	}
	.card__center_info {
		margin-top: 30px;
		background: rgb(3, 40, 31, 66%);
	}
	.container:nth-child(2) .card__center_info {
		padding-bottom: 45px;
	}
	.card__center_info {
		height: 100%;
	}
	.card__coin_back {
		width: 35px;
		position: absolute;
		left: 0;
		top: 76px;
		z-index: 1;
	}
	.container-0 {
		.card__center_coin {
			width: 110px;
			right: 11px;
			position: absolute;
		}
		.card__coin_back1 {
			position: absolute;
			bottom: 25px;
			left: 0;
			width: 70px;
			z-index: 1;
		}
	}
	.container-1 {
		.card__center_coin {
			width: 110px;
			right: -30px;
			position: absolute;
		}
		.card__coin_back1 {
			display: none;
		}
	}
	.card__block {
		box-shadow: 0px 0px 10px white;
	}
	.card__coin_fishka {
		height: 100%;
		top: 0;
		width: 100%;
		position: absolute;
		display: flex;
		display: none;

		img:nth-child(1) {
			top: 0;
			position: absolute;
			width: 80px;
		}
		img:nth-child(2) {
			bottom: 0;
			width: 100px;
		}
	}
}

.card__ecosystem--gate {
	.card__container--eco {
		background-size: cover !important;
		background-position: center !important;
		background-repeat: no-repeat;
	}
	.card__footer-eco {
		margin-left: -6px;
		margin-right: -6px;
		margin-bottom: -8px;
	}
	.card__block--bottom {
		border-bottom-left-radius: 18px;
		border-bottom-right-radius: 18px;
	}
}
#ecosystem--gate {
	.card__container {
		background-size: cover !important;
		background-position: center !important;
		background-repeat: no-repeat;
	}
	.container-1 {
		.card__center_info {
			padding: 13px 13px;
			// margin-top: 0px;
		}
	}
	.container-0 {
		.card__container--eco {
			justify-content: flex-start;
		}
		.card__center_info {
			margin-top: 65px;
		}
	}
}
.eco-section {
	position: relative;
	.pz-odessey3 {
		top: 15%;
	}
}

@media (min-width: 685px) {
	.eco-section {
		display: flex;
	}
	.eco-section .container-0 {
		justify-content: flex-end;
	}
	.eco-section .container-1 {
		justify-content: flex-start;
	}
}
@media (min-width: 800px) {
	.eco-section .container-0 {
		margin-right: 45px;
	}
	.eco-section .container-1 {
		margin-left: 45px;
	}
}
@media (max-width: 768px) {
	.eco-puzzle1 {
		display: none;
	}
	.eco-puzzle2 {
		display: none;
	}
}

.container-1 {
	.radial-blur {
		width: 484px;
		height: 484px;
		left: 0px;
		bottom: 1px;
	}
	.eco-puzzle {
		display: none;
	}
}
.container-0 {
	.radial-blur {
		width: 267px;
		height: 267px;
		right: 140px;
		top: -65px;
	}
	.eco-puzzle {
		z-index: 50;
		position: absolute;
		bottom: 47px;
		right: 27px;
		width: 130px;
	}
}
.radial-blur {
	// background: rgb(248, 248, 8);
	background: radial-gradient(circle, rgba(248, 248, 8, 0.4) 21%, rgba(146, 212, 0, 0.01) 70%);
	border-radius: 50%;
	// left: 96px;
	// bottom: 1px;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 356px;
	height: 356px;
	position: absolute;
}
.radial-blur1 {
	left: -203px;
	bottom: 0;
}
.radial-blur2 {
	right: -203px;
	bottom: -100px;
	z-index: -1;
}
.eco-puzzle {
	position: absolute;
}
.eco-puzzle1 {
	top: -120px;
	width: 136px;
	left: 0;
}
.eco-puzzle2 {
	right: 0;
	bottom: 120px;
	width: 128px;
}

#ecosystem--contacts {
	.radial-blur {
		background: radial-gradient(circle, rgba(93, 255, 206, 0.4) 21%, rgba(93, 255, 206, 0.01) 70%);
	}
	.radial-blur-card2 {
		bottom: -15px;
		right: -135px;
	}
	.radial-blur-card1 {
		top: -110px;
		left: -167px;
	}
}
#ecosystem--mine {
	.radial-blur {
		background: radial-gradient(circle, rgba(10, 134, 255, 0.4) 21%, rgba(145, 96, 255, 0.01) 70%);
	}
}

#ecosystem--exhchange {
	.radial-blur {
		background: radial-gradient(circle, rgba(16, 45, 164, 0.4) 21%, rgba(16, 45, 164, 0.01) 70%);
	}
	.radial-blur-card2 {
		bottom: -15px;
		right: -135px;
	}
	.radial-blur-card1 {
		top: -110px;
		left: -167px;
	}
}

#ecosystem--soon {
	.radial-blur {
		background: radial-gradient(circle, rgba(93, 255, 206, 0.4) 21%, rgba(93, 255, 206, 0.01) 70%);
	}
}
.sroll-button-down {
	display: none;
	position: absolute;
	background: url('../../../assets/images/gate/card/arrowMob.png');
	width: 38px;
	height: 35px;
	bottom: -52px;
	left: 50%;
	margin-left: -18px;
	transform: rotate(87deg);
}
@media screen and (max-width: 768px) {
	.sroll-button-down {
		display: block;
	}
	.swiper-container {
		padding-bottom: 122px !important;
	}
}
.tabletk {
	position: absolute;
}
.tabletL {
	left: 0;
	top: 22px;
	width: 56px;
}
.tabletR {
	right: 0;
	top: 530px;
	width: 70px;
}
.card__ecosystem--domino {
	.card__container {
		background-size: cover !important;
		background-position: center !important;
		background-repeat: no-repeat;
	}
	.card__title {
		font-size: 32px;
	}
	.card__center_img {
		width: 160px;
	}
	.card__footer-eco {
		margin-left: -6px;
		margin-right: -6px;
		margin-bottom: -8px;
	}
}
#ecosystem--domino {
	.card__eco-heart {
		width: 33px;
	}
	.radial-blur {
		background: radial-gradient(circle, rgba(57, 46, 172, 0.4) 21%, rgba(57, 46, 172, 0.01) 70%);
	}
	.container {
		width: auto;
		margin: 0;
		margin-bottom: 60px;
	}
	.card__container {
		background-size: cover !important;
		background-position: center !important;
		background-repeat: no-repeat;
	}
	.card__center_coin {
		width: 130px;
		right: 0;
		position: absolute;
	}

	.card__center_info {
		margin-top: 20px;
		padding: 13px 13px;
		padding-bottom: 70px;
		background-color: rgba(0, 0, 0, 65%);
		// margin: 0 auto;
		position: relative;
	}
	.card__header {
		background-color: rgba(0, 0, 0, 65%);
		height: 89px;
	}
	.container-0 {
		.card__center_coin {
			bottom: -42px;
			width: 120px;
			transform: rotate(-14deg);
		}
	}

	@media (min-width: 800px) {
		.container-1 {
			margin: 0 80px;
		}
	}
	@media (min-width: 800px) {
		justify-content: center;
	}
}
.card__ecosystem--guds {
	.card__block--bottom {
		background: #f0f359;
		color: black;
	}
	.card__center_img {
		top: 11px;
		width: 310px;
	}
}
#ecosystem--guds {
	.card__eco-heart {
		width: 33px;
	}
	.card__logo-text {
		margin-top: 5px;
		max-width: 190px;
	}
	.radial-blur {
		background: radial-gradient(
			circle,
			rgba(170, 125, 238, 0.4) 21%,
			rgba(170, 125, 238, 0.01) 70%
		);
	}
	.container {
		width: auto;
		margin: 0;
		margin-bottom: 60px;
	}
	.card__container {
		background-size: cover !important;
		background-position: center !important;
		background-repeat: no-repeat;
		overflow: hidden;
	}
	.card__center_coin {
		width: 130px;
		right: 0;
		position: absolute;
	}

	.card__center_info {
		margin-top: 20px;
		margin-bottom: 20px;
		flex: 1;
		padding: 13px 13px;
		padding-bottom: 70px;
		background-color: rgba(0, 0, 0, 65%);
		// margin: 0 auto;
		position: relative;
	}
	.card__header {
		background-color: rgba(0, 0, 0, 65%);
		height: 89px;
	}
	.container-1 {
		.card__center_coin {
			bottom: -68px;
			width: 120px;
			transform: rotate(-14deg);
		}
		.card__center_info {
			padding-bottom: 20px;
		}
	}
	.container-2 {
		.card__center_coin {
			right: -44px;
			bottom: -26px;
		}
	}
	.container-0 {
		.card__center_coin {
			width: 140px;
			right: 14px;
		}
	}

	@media (min-width: 800px) {
		.container-1 {
			margin: 0 80px;
		}
	}
	@media (min-width: 800px) {
		justify-content: center;
	}
}
.card__ecosystem--desert {
	.card__footer--description {
		max-width: 245px;
	}
	.card__center_img {
		top: 40px;
		width: 328px;
	}
	.card__block--bottom {
		background: #f0f359;
		color: black;
	}
}
#ecosystem--desert {
	.card__eco-heart {
		width: 33px;
	}
	.container {
		height: 580px;
	}
	.card__logo-text {
		margin-top: 5px;
		max-width: 190px;
	}
	.radial-blur {
		background: radial-gradient(
			circle,
			rgba(241, 161, 106, 0.4) 21%,
			rgba(241, 161, 106, 0.01) 70%
		);
	}
	.card__container {
		background-size: cover !important;
		background-position: center !important;
		background-repeat: no-repeat;
		overflow: hidden;
	}
	.card__center_coin {
		width: 130px;
		right: 0;
		position: absolute;
	}

	.card__center_info {
		margin-top: 20px;
		margin-bottom: 20px;
		flex: 1;
		padding: 13px 13px;
		background-color: rgba(0, 0, 0, 35%);
		// margin: 0 auto;
		position: relative;
	}
	.card__header {
		background-color: rgba(0, 0, 0, 35%);
		height: 89px;
	}

	@media (min-width: 800px) {
		.container-1 {
			margin: 0 80px;
		}
	}
}
.card__ecosystem--farti {
	.card__container {
		background-size: cover !important;
		background-position: center !important;
		background-repeat: no-repeat;
	}
	.card__footer--description {
		max-width: 270px;
	}
	.card__block--bottom {
		background: linear-gradient(
			90deg,
			rgb(252, 96, 71) 0%,
			rgb(251, 113, 66) 55%,
			rgb(238, 64, 40) 100%
		);
	}
	.card__footer-eco {
		margin-left: -6px;
		margin-right: -6px;
		margin-bottom: -8px;
	}
}
#ecosystem--farti {
	.container {
		width: auto;
		margin: 0;
		margin-bottom: 60px;
	}
	.card__eco-heart {
		width: 33px;
	}

	.card__logo-text {
		margin-top: 5px;
		max-width: 190px;
	}
	.radial-blur {
		background: radial-gradient(circle, rgba(100, 65, 155, 0.4) 21%, rgba(100, 65, 155, 0.01) 70%);
	}
	.card__container {
		background-size: cover !important;
		background-position: center !important;
		background-repeat: no-repeat;
		overflow: hidden;
	}

	.card__center_coin {
		width: 130px;
		right: 0;
		position: absolute;
	}
	.card__center_info {
		margin-top: 20px;
		margin-bottom: 20px;
		flex: 1;
		padding: 13px 13px;
		background-color: rgba(0, 0, 0, 35%);
		// margin: 0 auto;
		position: relative;
	}
	.card__header {
		background-color: rgba(0, 0, 0, 35%);
		height: 89px;
	}
	.container-2 {
		.card__center_info {
			padding-bottom: 70px;
		}
		.card__center_coin {
			width: 167px;
			right: 6px;
			position: absolute;
			bottom: 88px;
			z-index: 1;
		}
		.card__footer-img {
			width: 48px;
		}
	}
	.container-0 {
		.card__center_info {
			padding-bottom: 50px;
		}
		.card__center_coin {
			width: 167px;
			right: 6px;
			position: absolute;
			bottom: -6px;
			z-index: 1;
			transform: rotateY(-180deg);
		}
	}
	.container-1 {
		.card__center_coin {
			width: 150px;
			left: -39px;
			position: absolute;
			bottom: 79px;
		}
	}
	@media (min-width: 800px) {
		justify-content: center;
	}
	@media (min-width: 800px) {
		.container-1 {
			margin: 0 80px;
		}
	}
	.card__block-text {
		line-height: 22px;
	}
}
.card__ecosystem--coin {
	.card__container {
		background-size: cover !important;
		// background-position: center !important;
		background-repeat: no-repeat;
	}
	.card__footer--description {
		display: none;
	}
	.card__block--bottom {
		background: #f0f359;
		color: black;
		margin-left: -14px;
		margin-right: -14px;
		margin-bottom: -4px;
	}
	.card__footer-eco {
		margin-top: 9px;
		margin-left: -6px;
		margin-right: -6px;
		margin-bottom: -8px;
		backdrop-filter: blur(40px);
	}
	.card__title {
		font-size: 29px;
	}
	.card__center_img {
		top: 50px;
		width: 302px;
	}
}
#ecosystem--coin {
	.card__eco-heart {
		width: 33px;
	}
	.container {
		height: 532px;
	}
	.card__logo-text {
		margin-top: 5px;
		max-width: 190px;
	}
	.radial-blur {
		background: radial-gradient(circle, rgba(10, 134, 255, 0.4) 21%, rgba(145, 96, 255, 0.01) 70%);
	}
	.card__container {
		background-size: cover !important;
		background-position: center !important;
		background-repeat: no-repeat;
		overflow: hidden;
	}
	.card__center_coin {
		width: 130px;
		right: 0;
		position: absolute;
	}

	.card__center_info {
		margin-top: 20px;
		margin-bottom: 20px;
		flex: 1;
		padding: 13px 13px;
		background-color: rgba(0, 0, 0, 35%);
		// margin: 0 auto;
		position: relative;
	}
	.card__header {
		background-color: rgba(0, 0, 0, 35%);
		height: 89px;
	}
	.container-0 {
		.coin-img {
			position: absolute;
			right: 5px;
			bottom: 60px;
			z-index: 1;
			width: 86px;
		}
	}
	.container-1 {
		.coin-img {
			display: none;
		}
	}

	@media (min-width: 800px) {
		.container-1 {
			margin: 0 80px;
		}
	}
	.coin-img {
		position: absolute;
		right: 0;
		bottom: 25px;
		z-index: 1;
	}
}
