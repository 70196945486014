.amount-block-line {
	height: 0px;
}
.rc-slider-handle {
	// background-image: url('../../images/gato-logo-small.svg');
	opacity: 1;
}
.swiper-pagination-bullet {
	background: #d9f343;
	position: relative;
	z-index: 1;
	width: 11px;
	height: 11px;
}
.swiper-pagination-bullet:before {
	content: '';
	position: absolute;
	border-radius: 50%;
	width: 17px;
	height: 17px;
	top: -3px;
	left: -3px;
	background: #d9f343;
	opacity: 0.4;
	z-index: 0;
}
.my-custom-pagination {
	padding: 19px 0;
	// margin: 0 auto;
	position: absolute;
	bottom: 0px !important;
}
.swiper-pagination {
	padding: 19px 0;
	position: absolute;
	bottom: 0px !important;
}

.swiper-container {
	overflow: visible;
}
.swiper-button-prev {
	top: 41%;
}
.swiper-button-next {
	top: 41%;
}
.swiper-button-prev,
.swiper-button-next {
	position: absolute;
	top: 41%;
	opacity: 0;
	z-index: 10;
	// transition: ease 0.5s all;
}
.swiper-container:hover {
	.swiper-button-next,
	.swiper-button-prev {
		opacity: 1;
	}
}
// .swiper-container {
// 	padding-bottom: 90px;
// }

@media screen and (max-width: 500px) {
	.swiper-anim {
		display: none !important;
	}

	.swiper-container {
		overflow: visible;
	}
	.swiper-button-prev,
	.swiper-button-next {
		opacity: 1;
	}
	.swiper-button-next {
		transition: all 0.3s ease-in-out 0s;
		animation: ring 1.5s infinite;
	}
	.swiper-button-prev {
		transition: all 0.3s ease-in-out 0s;
		animation: ringLeft 1.5s infinite;
	}

	@keyframes ring {
		0% {
			right: 0px;
			opacity: 1;
		}
		100% {
			right: 4px;
		}
	}
	@keyframes ringLeft {
		0% {
			left: 0px;

			opacity: 1;
		}
		100% {
			left: 4px;
		}
	}
}
